//@ts-check
import React, { useContext, useState } from "react"
import { Link, navigate } from "gatsby"

import { Button, Checkbox, Form } from "antd"
import { Auth } from "aws-amplify"
import { AuthContext } from "../../context/AuthContext"
import { isBrowser } from "../../utils/SSR"

const ForgotPassword = () => {
  const authContext = useContext(AuthContext)
  const [error, setError] = useState("")

  const confirmSubmit = async ({ email }) => {
    try {
      await Auth.forgotPassword(email.toLowerCase())

      //isBrowser() && window.localStorage.setItem("passwordResetEmail", email.toLowerCase())

      navigate("/forgot-password-confirm")
    } catch (e) {
      setError("Unable to log in with that email and password")
    }
  }

  const loginSubmitFailed = () => {
    console.log("form submit failed")
  }

  return (
    <div className="login-form">
      <Form
        name="basic"
        wrapperCol={{ span: 24 }}
        onFinish={confirmSubmit}
        onFinishFailed={loginSubmitFailed}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item
          label="Email"
          className="form-group"
          name="email"
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
            { required: true, message: "Please input your email!" },
          ]}
        >
          <input
            type="text"
            className="form-control"
            placeholder="Email Address"
            onChange={() => setError("")}
          />
        </Form.Item>

        <Form.Item>
          <Button htmlType="submit" style={{ height: "50px" }}>
            Get Password Rest Code
          </Button>
        </Form.Item>
        {error && <div>{error}</div>}
      </Form>
    </div>
  )
}

export default ForgotPassword
